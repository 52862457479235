<template>
    <div class="case">
        <div class="banner">
            <Banner :type="'NEWS'" :height="500"></Banner>
        </div>
        <div class="content">
            <div class="title">
                <span></span>
                新闻资讯
            </div>
            <div class="list">
                <div
                    class="block clear_fix"
                    @click="handle_to_detail(item)"
                    :key="index"
                    v-for="(item, index) in data.list"
                >
                    <div class="date fl" v-if="item.newsDate">
                        <span>{{item.newsDate.slice(-2)}}</span>
                        <p>{{item.newsDate.slice(0,7)}}</p>
                    </div>
                    <div class="detail fl">
                        <div class="item">
                            <div class="t">{{item.title}}</div>
                            <p>{{item.content}}</p>
                        </div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
            <div class="pager">
                <el-pagination
                    size="medium"
                    :page-size="6"
                    background
                    layout="pager"
                    :total="data.total"
                    @current-change="handle_current_change"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<style lang="scss" src="./news.scss" scoped></style>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import { useRouter } from "vue-router";
import Banner from "../../components/banner";

export default {
    setup(props) {
        let router = useRouter();

        let { proxy } = getCurrentInstance();
        const data = reactive({
            list: [],
            total: 0,
            currentPage: 1
        });

        const get_news_list = page => {
            proxy.$axios
                .get("/new/list", {
                    params: {
                        currentPage: page
                    }
                })
                .then(res => {
                    data.list = res.data.list;
                    data.total = res.data.total;
                });
        };

        const handle_current_change = page => {
            get_news_list(page);
        };

        const handle_to_detail = item => {
            let routeData = router.resolve({
                path: "/news-detail",
                query:{
                    id:item.id
                }
            });
            window.open(routeData.href, "_blank");
        };


        onMounted(() => {
            get_news_list(1);
        });

        return {
            data,
            handle_to_detail,
            handle_current_change
        };
    },
    components: {
        Banner
    }
};
</script>
